/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 113번째 로그에서는 파이썬 패턴 매칭, 엑셀 Lambda 함수 추가, OKKYCON 2021에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "파이썬 패턴 매칭 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ryanking13.github.io/2021/03/04/python-patma.html"
  }, "파이썬의 패턴 매칭 도입에 얽힌 이야기 - Morgenrøde")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lwn.net/Articles/693493/"
  }, "2016년 파이썬 패턴 매칭 브레인스토밍, Match statement brainstorm")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/dev/peps/pep-0572/"
  }, "귀도가 파이썬의 자비로운 종신독재자에서 은퇴하는 사건의 계기 - PEP 572")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://lwn.net/ml/python-dev/CAP7+vJLdGgbQ5kozPjMi5hCTmEZ5cr+8MFOoY_bNCT0pHTZNwg@mail.gmail.com/"
  }, "[Python-Dev] PEP 622: Structural Pattern Matching")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mybinder.org/v2/gh/gvanrossum/patma/master?urlpath=lab/tree/playground-622.ipynb"
  }, "A PEP 622 Playground¶")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://discuss.python.org/t/gauging-sentiment-on-pattern-matching/5770/21"
  }, "Larry Hastings - CPython core developer")), "\n"), "\n", React.createElement(_components.h3, null, "엑셀에 Lambda 함수 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/msexcel/status/1359248462932549635"
  }, "엑셀 공식 트윗 - Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.microsoft.com/en-us/research/blog/lambda-the-ultimatae-excel-worksheet-function/"
  }, "LAMBDA: The ultimate Excel worksheet function - Microsoft")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://book.naver.com/bookdb/book_detail.nhn?bid=9078133"
  }, "컴퓨터과학이 여는 세계(저자 이광근) - 네이버 책")), "\n"), "\n", React.createElement(_components.h3, null, "OKKYCON: 2021"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.okkycon.com/2021/"
  }, "OKKYCON: 2021")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://naleejang.tistory.com/37"
  }, "KTH H3 Developer Conference 2012를 다녀와서")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20121011180600017"
  }, "KTH, 개발자 컨퍼런스 'H3' 개최 - 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://zdnet.co.kr/view/?no=20130307071837"
  }, "인터넷업계 칼바람 지속…KTH 구조조정 - ZDNet Korea")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
